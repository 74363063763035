<template>
  <div>
    <cabecalho-pagina
      :titulo="$t('modulos.certificado_terceiro.titulos.listagem')"
      :total-registros="tabela.quantidadeItens"
      :titulo-botao-novo="$t('modulos.certificado_terceiro.titulos.novo')"
      :sem-botao-ok="
        !buscarPermissao('CertificadoTerceiro', 'Inserir') ||
          participanteLogadoConta
      "
      :sem-filtro="!buscarPermissao('CertificadoTerceiro', 'Listar')"
      @filtrar="filtrar"
      @abrirNovo="abrirNovo"
    >
      <template v-slot:filtro="{ filtro }">
        <filtro :filtro="filtro" />
      </template>
    </cabecalho-pagina>
    <botao-padrao
      v-if="buscarPermissao('CertificadoTerceiro', 'Deletar') && podeExcluir"
      class="my-2"
      outlined
      color="danger"
      @click="excluirRegistros()"
    >
      {{ $t('geral.botoes.excluir') }}
    </botao-padrao>
    <tabela-padrao-prime-vue
      v-model="tabela.selecionados"
      filtros-com-colunas
      class="mt-2"
      :colunas="colunasTabelas"
      :dados="tabela.dados"
      mostrar-acoes
      selecionar-colunas
      sem-selecionar-todos
      :mostrar-seletor="buscarPermissao('CertificadoTerceiro', 'Deletar')"
      :quantidade-itens="tabela.quantidadeItens"
      :por-pagina="tabela.porPagina"
      :quantidade-paginas="tabela.quantidadePaginas"
      :pagina="tabela.paginaAtual"
      @paginar="listarRegistros"
      @filtrarGlobal="filtrarGlobal"
      @filtroGlobal="filtroGlobal"
    >
      <template v-slot:acoes="{ slotProps }">
        <dropdown-padrao
          text
          color="secondary"
        >
          <template #botao>
            <v-icon> $dotsVertical </v-icon>
          </template>
          <dropdown-padrao-item
            v-if="buscarPermissao('CertificadoTerceiro', 'Editar')"
            @click="editar(slotProps.data)"
          >
            {{ $t('geral.botoes.editar') }}
          </dropdown-padrao-item>
          <dropdown-padrao-item
            v-if="buscarPermissao('CertificadoTerceiro', 'Visualizar')"
            @click="visualizar(slotProps.data)"
          >
            {{ $t('geral.botoes.visualizar') }}
          </dropdown-padrao-item>
          <dropdown-padrao-item
            v-if="buscarPermissao('CertificadoTerceiro', 'Definir Derivas')"
            :disabled="podeDefinirDeriva(slotProps.data)"
            @click="irParaDefinirDerivas(slotProps.data)"
          >
            {{ $t('modulos.certificado_terceiro.botoes.definir_derivas') }}
          </dropdown-padrao-item>
          <dropdown-padrao-item
            v-if="buscarPermissao('CertificadoTerceiro', 'Avaliar Certificado')"
            :disabled="podeAvaliar(slotProps.data)"
            @click="avaliarCertificado(slotProps.data)"
          >
            {{ $t('modulos.certificado_terceiro.botoes.avaliar_certificado') }}
          </dropdown-padrao-item>
          <dropdown-padrao-item
            v-if="buscarPermissao('CertificadoTerceiro', 'Visualizar')"
            :disabled="slotProps.data.situacaoCertificado != 'Assinado'"
            @click="abrirGraficoTendecia(slotProps.data)"
          >
            {{ $t('modulos.instrumento.botoes.grafico_tendencia') }}
          </dropdown-padrao-item>
        </dropdown-padrao>
      </template>
      <template v-slot:situacaoCertificado="{ slotProps }">
        <div
          :class="`${
            formatarSituacaoCertificado(slotProps.data.situacaoCertificado)?.cor
          }--text`"
        >
          {{
            formatarSituacaoCertificado(slotProps.data.situacaoCertificado)
              ?.text
          }}
        </div>
      </template>
      <template v-slot:situacaoAvaliacao="{ slotProps }">
        <div
          :class="`${
            formatarSituacaoAvaliacao(slotProps.data.situacaoAvaliacao)?.cor
          }--text`"
        >
          {{
            formatarSituacaoAvaliacao(slotProps.data.situacaoAvaliacao)?.text
          }}
        </div>
      </template>
    </tabela-padrao-prime-vue>
  </div>
</template>
<script>
import { CertificadoTerceiroService } from '@common/services/cadastros/';
import helpers from '@common/utils/helpers';
import CabecalhoPagina from '@components/layout/CabecalhoPagina';
import { mapGetters } from 'vuex';
import Filtro from './filtro.vue';

export default {
  components: {
    CabecalhoPagina,
    Filtro,
  },
  data() {
    return {
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'codigo',
            text: this.$t('modulos.certificado_terceiro.tabela.codigo'),
          },
          {
            value: 'calibradoEm',
            text: this.$t('modulos.certificado_terceiro.tabela.calibradoEm'),
            formatter: (v) => {
              if (v) return helpers.formatarDataBr(v);
            },
          },
          {
            value: 'codigoInstrumento',
            text: this.$t(
              'modulos.certificado_terceiro.tabela.codigo_instrumento'
            ),
          },
          {
            value: 'tipoInstrumento.nome',
            text: this.$t(
              'modulos.certificado_terceiro.tabela.tipo_instrumento'
            ),
          },
          {
            value: 'cliente.nome',
            text: this.$t('modulos.certificado_terceiro.tabela.cliente'),
          },
          {
            value: 'laboratorio.nome',
            text: this.$t('modulos.certificado_terceiro.tabela.laboratorio'),
          },
          {
            value: 'situacaoCertificado',
            text: this.$t(
              'modulos.certificado_terceiro.tabela.situacao_certificado'
            ),
          },
          {
            value: 'situacaoAvaliacao',
            text: this.$t(
              'modulos.certificado_terceiro.tabela.situacao_avaliacao'
            ),
          },

          {
            value: 'usuarioExecutante.nome',
            text: this.$t(
              'modulos.certificado_terceiro.tabela.usuarioExecutante'
            ),
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
      filtroAplicado: { filter: '' },
    };
  },
  computed: {
    ...mapGetters('Autenticacao', ['participanteLogadoConta']),
    podeExcluir: function () {
      return this.tabela.selecionados.length > 0;
    },
    colunasTabelas: function () {
      const colunas = [
        {
          value: 'codigo',
          text: this.$t('modulos.certificado_terceiro.tabela.codigo'),
        },
        {
          value: 'calibradoEm',
          text: this.$t('modulos.certificado_terceiro.tabela.calibradoEm'),
          formatter: (v) => {
            if (v) return helpers.formatarDataBr(v);
          },
        },
        {
          value: 'codigoInstrumento',
          text: this.$t(
            'modulos.certificado_terceiro.tabela.codigo_instrumento'
          ),
        },
        {
          value: 'tipoInstrumento.nome',
          text: this.$t('modulos.certificado_terceiro.tabela.tipo_instrumento'),
        },
        {
          value: 'ordemServico.codigo',
          text: this.$t('modulos.certificado_terceiro.tabela.ordem_servico'),
        },

        {
          value: 'cliente.nome',
          text: this.$t('modulos.certificado_terceiro.tabela.cliente'),
        },
        {
          value: 'laboratorio.nome',
          text: this.$t('modulos.certificado_terceiro.tabela.laboratorio'),
        },
        {
          value: 'situacaoCertificado',
          text: this.$t(
            'modulos.certificado_terceiro.tabela.situacao_certificado'
          ),
        },
        {
          value: 'situacaoAvaliacao',
          text: this.$t(
            'modulos.certificado_terceiro.tabela.situacao_avaliacao'
          ),
        },

        {
          value: 'usuarioExecutante.nome',
          text: this.$t(
            'modulos.certificado_terceiro.tabela.usuarioExecutante'
          ),
        },
        {
          value: 'flagLiberadoCliente',
          text: this.$t('modulos.certificado_terceiro.tabela.liberarClientes'),
          formatter: (v) => {
            return helpers.BoleanoEnum.find((el) => el.value === v)?.text;
          },
        },
      ];
      if (this.participanteLogadoConta) {
        return [...colunas];
      }
      colunas.pop();
      return [...colunas];
    },
  },
  created() {
    helpers.redirecionarSemPermissao(this, 'CertificadoTerceiro', 'Listar');
    const filtros = this.buscarStateFiltros();
    this.filtroAplicado = filtros?.filtroAplicado ?? { filter: '' };
    this.tabela.paginaAtual = filtros?.paginaAtual ?? 1;
    this.tabela.porPagina = filtros?.porPagina ?? 10;
  },
  mounted() {
    this.listarRegistros();
    this.$store.dispatch(
      'Layout/alterarTituloPagina',
      this.$t('modulos.certificado_terceiro.titulos.listagem')
    );
  },
  methods: {
    buscarPermissao(funcionalidade, funcionalidadeAcao) {
      return helpers.buscarStatePermissoes(
        this,
        funcionalidade,
        funcionalidadeAcao
      );
    },
    filtrar: function (filtro) {
      this.filtroAplicado = { ...filtro, filter: this.filtroAplicado.filter };
      this.listarRegistros();
    },
    abrirGraficoTendecia: function (certificado) {
      this.$router.push({
        name: 'instrumento-certificado-grafico-tendencia',
        params: {
          instrumentoId: certificado.instrumentoId,
          certificadoId: certificado.id,
        },
        query: {
          retorno: 'certificado-terceiro',
        },
      });
    },
    abrirNovo: function () {
      this.$router.push({ name: 'certificado-terceiro-novo' });
    },
    listarRegistros: function (
      paginaAtual = this.tabela.paginaAtual,
      porPagina = this.tabela.porPagina
    ) {
      this.tabela.porPagina = porPagina;
      this.salvarStateFiltros({
        filtroAplicado: this.filtroAplicado,
        paginaAtual,
        porPagina,
      });
      let parametros = {
        page: paginaAtual,
        amountPerPage: porPagina,
        ...this.filtroAplicado,
      };
      this.$store.dispatch('Layout/iniciarCarregamento');
      CertificadoTerceiroService.listar(parametros)
        .then((res) => {
          this.tabela.selecionados = [];
          this.tabela.dados = res.data.items;

          this.tabela.quantidadeItens = res.data.totalItems;
          this.tabela.quantidadePaginas = res.data.totalPages;
          this.tabela.paginaAtual = paginaAtual;
          this.tabela.porPagina = porPagina;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    formatarSituacaoAvaliacao: function (v) {
      return helpers.SituacaoAvaliacaoEnum.find((el) => el.value == v);
    },
    formatarSituacaoCertificado: function (v) {
      return helpers.SituacaoCertificadoEnum.find((el) => el.value == v);
    },
    editar: function (item) {
      if (item.situacaoCertificado == 'Assinado') {
        this.confirmar(
          this.$t('geral.titulos.atencao'),
          this.$t('modulos.certificado_terceiro.popup.mensagem')
        ).then(() => {
          this.$store.dispatch('Layout/iniciarCarregamento');
          CertificadoTerceiroService.voltarAndamento(item.id).then(() => {
            this.$router
              .push({
                name: 'certificado-terceiro-editar',
                params: { id: item.id },
              })
              .finally(() => {
                this.$store.dispatch('Layout/terminarCarregamento');
              });
          });

          return;
        });
      } else {
        this.$router.push({
          name: 'certificado-terceiro-editar',
          params: { id: item.id },
        });
      }
    },
    podeEditarClienteOuConta: function () {
      // return (
      //   (this.participanteLogadoConta && item.ordemServico?.id) ||
      //   (!this.participanteLogadoConta && !item.ordemServico?.id)
      // );
      return true;
    },
    excluirRegistros: function () {
      this.confirmarExcluir().then(() => {
        this.$store.dispatch('Layout/iniciarCarregamento');
        const listaIdExclusao = this.tabela.selecionados.map((s) => s.id);
        CertificadoTerceiroService.excluir(listaIdExclusao)
          .then(() => {
            this.toastSucesso(
              this.$t('modulos.certificado_terceiro.exclusao_sucesso')
            );
            this.listarRegistros();
          })
          .finally(() => {
            this.$store.dispatch('Layout/terminarCarregamento');
          });
      });
    },

    podeDefinirDeriva: function (item) {
      return !(
        item.situacaoAvaliacao == 'PendenteDerivas' &&
        item.situacaoCertificado == 'Assinado'
      );
    },
    podeAvaliar: function (item) {
      return (
        item.situacaoAvaliacao == 'NaoIniciada' ||
        item.situacaoCertificado == 'EmAndamento' ||
        item.situacaoAvaliacao == 'PendenteDerivas'
      );
    },
    visualizar: function (item) {
      this.$router.push({
        name: 'certificado-terceiro-visualizar',
        params: { id: item.id, visualizar: true },
      });
    },
    irParaDefinirDerivas: function (item) {
      this.$router.push({
        name: 'certificado-terceiro-definir-derivas',
        params: {
          calibracaoId: item.id,
          instrumentoId: item.instrumentoId,
          retorno: 'certificado-terceiro',
        },
      });
    },
    avaliarCertificado: function (item) {
      this.$router.push({
        name: 'certificado-avaliacao',
        params: { calibracaoId: item.id, retorno: 'certificado-terceiro' },
      });
    },
    buscarStateFiltros() {
      return this.$store.getters['Filtros/filtros'][this.$route.name];
    },
    salvarStateFiltros(dados) {
      this.$store.dispatch('Filtros/adicionarFiltro', {
        rota: this.$route.name,
        dados,
      });
    },
    filtroGlobal(v) {
      this.filtroAplicado.filter = v;
    },
    filtrarGlobal() {
      this.listarRegistros();
    },
  },
};
</script>
